
@media(max-width:1600px){}
@media(max-width:1199px){
    .banner-sec .text-box p{
        font-size: 16px;
    }
    .auto-following-sec .text-box h3 {
        font-size: 28px;
        line-height: 40px;
    }
}
@media(max-width:991px) {
    .banner-sec .image-holder a.richard-btn span {
        width: 35px;
        height: 35px;
        margin-left: 10px;
    }
    .banner-sec .image-holder a.richard-btn span img{
        height: 13px;
    }
    .banner-sec .image-holder a.richard-btn {
        font-size: 13px;
        max-width: 190px;
        height: 70px;
    }
    .banner-sec .text-box h1 {
        font-size: 42px;
        max-width: 340px;
    }
    .banner-sec .text-box p {
        font-size: 14px;
    }
    .round-img {
        top: -134px;
        right: -130px;
        max-width: 550px;
    }
    .auto-following-sec .title:after{
        width: 76%;
    }
    .auto-following-sec .text-box h3 {
        font-size: 26px;
        line-height: 38px;
    }
    .ball-white-second {
        bottom: 55px;
        right: -5px;
    }
    .ball-white-first {
        width: 40px;
        height: 40px;
        top: -45px;
    }
    .round-ball-price2 {
        bottom: 30px;
        right: 60px;
        width: 45px;
        height: 45px;
    }
    .testimonial-inner .swiper-slide-active .testimonial-box .image-holder {
        max-width: 130px;
        width: 100%;
    }
    .testimonial-inner .swiper-slide-active {
        width: 500px !important;
        margin-left: -143px;
    }
    .testimonial-box .text-box h3{
        font-size: 16px;
        margin-bottom: 2px;
    }
    .testimonial-box .text-box {
        padding-left: 10px;
        padding-right: 34px;
    }
    .testimonial-sec .title:after{
        width: 83%;
    }
    .testimonial-round-ball-first {
        width: 35px;
        height: 35px;
        left: 50px;
        bottom: 40px;
    }
    .testimonial-inner {
        margin: 125px auto 0;
        padding: 0 10px;
    }
    .testimonial-inner .swiper-btn{
        top: 56%;
    }
}
@media(max-width:767px) {
    .container {
        max-width: 100%;
    }
    header {
        padding: 15px 0;
       
    }
    body.active header{
         position: fixed;
    }
    .logo h2 {
        max-width: 80px;
        width: 100%;
        line-height: 17px;
    }
    .banner-sec {
        height: auto;
        background: #121212 none;
        padding: 115px 0 60px 0;
    }
    .banner-sec:after{
        display: none;
    } 
    .banner-sec .image-holder {
        margin-top: 30px;
    }
    .sidebar{
        width: 360px;
    }
    .sidebar-body ul li {
        margin-bottom: 20px;
    }
    .sidebar-body ul li a { 
        font-size: 30px; 
    }
    .round-img {
        top: auto;
        right: auto;
        max-width: 550px;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: -50px;
    }
    .about-waves img{
        max-width: inherit;
    }
    .about-sec .swiper-btn{
        max-width: 255px;
    }
    .auto-following-sec .title:after {
        width: 70%;
    }
    .auto-following-sec .image-holder {
        padding-left: 0;
    }
    .auto-following-sec {
        padding: 30px 0 50px;
    }
    .testimonial-sec {
        padding: 30px 0 50px;
    }
    .testimonial-inner .swiper-slide-active {
        width: 100% !important;
        margin-left: 0;
    }
    .testimonial-box .text-box {
        padding-left: 10px;
        padding-right: 30px;
    }
    .testimonial-sec .title:after {
        width: 80%;
    }
    .testimonial-inner {
        margin: 70px auto 0;
    }
    .testimonial-round-ball-second{
        top: 50px;
    }
    .testimonial-inner .swiper-pagination {
        margin: 50px 0 0 0;
    }
    .testimonial-round-ball-first {
        bottom: 20px;
    }
    .testimonial-inner .swiper-btn {
        top: 53%;
        padding: 0 3px;
    } 
}
@media(max-width:575px) {
    .sidebar {
        width: 100%;
    }
    .sidebar-header a {
        margin-right: 0;
    }
    .sidebar-header img:not(#menu-close) {
        height: 0;
    }
    .banner-sec {
        padding: 115px 0 43px 0;
    }
    .banner-sec .text-box h1 {
        font-size: 34px;
        max-width: 277px;
        margin: 0 auto 9px;
        text-align: center;
    }
    .banner-sec .text-box h1 span {
        font-size: 13px;
        width: 38px;
        height: 38px;
        top: -43px;
        right: 0;
    }
    .banner-sec .text-box p {
        font-size: 15px;
        line-height: 18px;
        text-align: center;
    }
    .banner-sec .image-holder {
        max-width: 160px;
        margin: 0 auto;
    }
    .banner-sec .image-holder a.richard-btn {
        font-size: 8px;
        max-width: 119px;
        height: 38px;
        bottom: -10px
    }
    .banner-sec .image-holder a.richard-btn span img {
        height: 9px;
    }
    .banner-sec .image-holder a.richard-btn span {
        width: 25px;
        height: 25px;
        margin-left: 10px;
    }
    .banner-sec .text-box a {
        font-size: 15px;
        width: 198px;
        height: 50px; 
        margin-top: 35px;
    }
    .banner-sec .text-box a img{
        margin-left: 9px;
    }
    .round-img {
        max-width: 362px;
        bottom: -97px;
    }
    .title h2 {
        text-align: center;
        font-size: 15px;
        padding-right: 0;
        display: block;
    }
    .title:after{
        display: none;
    }
    .about-sec{
        padding: 0;
    }
    .about-inner:before,
    .about-inner:after{
        display: none;
    }
    .about-waves img {
        max-width: 100%;
    }
    .about-waves {
        top: 53%;
        max-width: 320px;
        width: 100%;
    }
    .about-inner {
        margin: 26px auto 72px;
    }
    .about-sec .swiper-pagination {
        margin: 14px 0 0 0;
    }
    .round-ball.round-ball-first {
        top: -18px;
        left: auto;
        right: 28px;
    }
    .round-ball.round-ball-second {
        bottom: -48px;
        left: 28px; 
    }
    .round-ball.round-ball-thrd{
        display: none;
    }
    .auto-following-sec {
        padding: 30px 0 17px;
    }
    .auto-following-sec .text-box h3 {
        font-size: 25px;
        text-align: center;
        line-height: 30px;
    }
    .ball-white-first {
        width: 22px;
        height: 22px;
        top: -65px;
        left: 24px !important;
        transform: translate(0, 0) !important;
    }
    .auto-following-sec .title {
        margin-bottom: 33px;
    }
    .round-ball-white.ball-white-second {
        bottom: -24px;
        right: 35px !important;
        width: 38px;
        height: 38px;
        left: auto !important;
        transform: translate(0, 0) !important;
    }
    .round-ball-white {
        width: 12px;
        height: 12px;
        bottom: 22px;
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .heading h3 {
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        max-width: 210px;
        margin: 0 auto 6px;
    }
    .heading span {
        font-size: 12px;
        text-align: center;
    }
    .round-ball-price3 {
        right: auto;
        left: 0;
        top: -86px;
    }
    .price-sec {
        padding: 34px 0 25px;
    }
    .price-box{
        margin: 15px 5px;
    }
    .price-inner .col-md-4:nth-child(2){
        order: 1;
    }
    .price-inner .col-md-4:nth-child(3){
        order: 2;
    }
    .price-inner .col-md-4:first-child{
        order: 3;
    }
    .price-head h4{
        font-size: 23px;
    }
    .price-head span{
        font-size: 11px;
    }
    .price-head h3{
        font-size: 31px;
    }
    .price-head p{
        font-size: 9px;
    }
    .price-body ul li{
        font-size: 14px;
    }
    .price-body a {
        width: 84px;
        height: 84px;
        font-size: 14px;
    }
    .price-inner .text-box p {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 22px;
    }
    .price-inner .text-box a{
        font-size: 15px;
    }
    .price-inner .text-box {
        margin: 13px 0 0 0;
    }
    .round-ball-price2{
        width: 16px;
        height: 16px;
        bottom: 36.2%;
        right: 12px;
    }
    .round-ball-price1 {
        top: 35%;
        left: 9px;
        width: 16px;
        height: 16px;
    }
    .testimonial-sec {
        padding: 30px 0 64px;
    }
    .testimonial-box {
        flex-direction: column;
    }
    .testimonial-inner .swiper-slide-active .testimonial-box .image-holder img {
        border-radius: 24px;
    }
    .testimonial-inner .swiper-container{
        padding: 78px 0 0 0;
    }
    .testimonial-inner .swiper-slide-active .testimonial-box .image-holder {
        max-width: 110px;
        width: 100%;
        margin: -78px 0px 0 0;
    }
    .testimonial-box .text-box {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }
    .testimonial-box .text-box h3 span{
        display: none;
    }
    .testimonial-box .text-box h3 {
        font-size: 15px;
        justify-content: center;
        padding: 17px 0 13px;
        margin: 0;
    }
    .testimonial-box .text-box p{
        text-align: center;
        max-width: 265px;
        width: 100%;
        margin: 0 auto;
    }
    .testimonial-inner .swiper-pagination-bullet{
        margin: 0 16px;
        width: 6px;
        height: 6px;
    }
    .testimonial-inner {
        margin: 10px auto 0;
    }
    .testimonial-inner .swiper-pagination {
        margin: 21px 0 0 0;
    }
    .testimonial-round-ball-first {
        bottom: auto;
        top: 50px;
        left: auto;
        right: 35px;
        width: 38px;
        height: 38px;
    }
    .testimonial-round-ball-second {
        top: -15px;
        left: 38px;
        width: 22px;
        height: 22px;
    }
    .testimonial-inner .swiper-btn {
        top: auto;
        padding: 0 8px;
        bottom: -10px;
    }
    footer {
        padding: 24px 0 27px;
    }
    .footer-inner{
        flex-direction: column-reverse;
    }
    .footer-copy{
        margin-bottom: 30px;
    }
    .sidebar-body ul li a {
        font-size: 26px;
    }

}
@media(max-width:374px) {}